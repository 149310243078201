<template>
    <div class="auth-header">
        <h1>Welcome to Witcloud</h1>
        <ul>
            <li v-for="feature in features" :key="feature">
                <InlineSvg :src="require('@/assets/img/checkmark-circled.svg')" /><span>{{ feature }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
    components: {
        InlineSvg,
    },
    data() {
        return {
            features: ['14 days free trial', 'No code required', 'Automated reports'],
        }
    },
}
</script>

<style lang="scss">
.auth-header {
    width: 100%;
    box-sizing: border-box;

    h1 {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 800;
        font-size: 35px;
        line-height: 43px;
        text-align: center;
        margin-bottom: 22px;
        padding: 0 10px;
        box-sizing: border-box;
    }

    ul {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        list-style: none;
        margin: -5px 0 45px;
        box-sizing: border-box;
        padding: 0 10px;
        width: calc(100% - 20px);
    }

    li {
        svg {
            display: inline;
            vertical-align: bottom;
            margin-right: 10px;
        }

        font-family: 'Objectivity';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        margin: 5px 12.5px;
    }
}
</style>
