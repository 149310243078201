<template>
    <div class="app">
        <main class="main">
            <AuthNavbar />
            <AuthHeader />
            <div class="container-fluid">
                <router-view></router-view>
            </div>
        </main>
    </div>
</template>

<script>
import AuthNavbar from '@/components/Auth/Navbar.vue'
import AuthHeader from '@/components/Auth/Header.vue'

export default {
    components: {
        AuthNavbar,
        AuthHeader,
    },
}
</script>
